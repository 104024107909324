// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contentful-blog-slug-js": () => import("./../../../src/pages/{ContentfulBlog.slug}.js" /* webpackChunkName: "component---src-pages-contentful-blog-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-topic-template-js": () => import("./../../../src/templates/topic-template.js" /* webpackChunkName: "component---src-templates-topic-template-js" */)
}

